.multi-widget{
    height: 100%;
    md-tabs{
        height: 100%;

        .widget{
            height: 100%;
            margin: 0;
        };
    }
}

