@loader--width: 250px;
@loader-dot--size: 20px;
@loader-text--size: 25px;

.app-loader {
  .loader {
    height: @loader-dot--size;
    width: @loader--width;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    .dot {
      animation-name: loader;
      animation-timing-function: ease-in-out;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      height: @loader-dot--size;
      width: @loader-dot--size;
      border-radius: 100%;
      position: absolute;
      border: 2px solid @my-background;

      &:first-child {
        background-color: @brand-primary;
        animation-delay: 0.5s
      }
      &:nth-child(2) {
        background-color: @brand-primary;
        animation-delay: 0.4s;
      }
      &:nth-child(3) {
        background-color: @brand-primary;
        animation-delay: 0.3s;
      }
      &:nth-child(4) {
        background-color: @brand-primary;
        animation-delay: 0.2s;
      }
      &:nth-child(5) {
        background-color: @brand-primary;
        animation-delay: 0.1s;
      }
      &:nth-child(6) {
        background-color: @brand-primary;
        animation-delay: 0s;
      }
    }

    .text {
      position: absolute;
      top: 200%;
      left: 0;
      right: 0;
      width: 7rem;
      margin: auto;
      &:after {
        content: "Loading";
        font-weight: bold;
        font-size: @loader-text--size;
        animation-name: loading-text;
        animation-duration: 3s;
        animation-iteration-count: infinite
      }
    }
  }

  @keyframes loader {
    15% {transform: translateX(0);}
    45% {transform: translateX(@loader--width - @loader-dot--size);}
    65% {transform: translateX(@loader--width - @loader-dot--size);}
    95% {transform: translateX(0);}
  }


  @keyframes loading-text{
    0% {content: "Loading";}
    25% {content: "Loading.";}
    50% {content: "Loading..";}
    75% {content: "Loading...";}
  }
}