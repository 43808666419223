.infinite-list-outer{
    height: 100%;
    display: block;
    position: relative;

    .il-counter{
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: rgba(255,255,255,0.4);
        font-size: @font-size-small;
        padding-right: 5px;
    }

    .infinite-list-inner{
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;

        -webkit-mask-image: -webkit-gradient(linear, left top, 
            left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(5%,rgba(255,255,255,1)),
            color-stop(85%,rgba(255,255,255,1)),color-stop(100%,rgba(255,255,255,0)));

        .md-virtual-repeat-container {
            height: 100%;

            .md-virtual-repeat-offsetter{

                margin-top: 64px;
                margin-bottom: 50px;

                @fsize: @font-size-base;
                @line-height: @fsize+3px;
                @abstract-line-num: 3;
                @twitter-text-line-num: 2;
                @twitter-line-num: 1+@twitter-text-line-num;
                @line-num: 2+@abstract-line-num;

                .repeated-item {
                    padding-left: 5px;
                    padding-right: 15px;
                    .repeated-item-inner{
                        height: @line-num*@line-height + 5px;
                        //overflow-y: hidden;
                        margin-bottom: 10px;
                        margin-top: 10px;
                        font-size: @fsize;
                        line-height: @line-height;
                        md-checkbox {
                            .md-icon {
                                margin-top: -4px;
                                margin-left: 4px;
                                transform: scale(.6);
                                border-width: 1px;
                                border-style: dashed;
                            }
                        }
                        .title{
                            height: @line-height;
                            margin-top: 0px;
                            margin-bottom: 4px;
                            font-weight: 500;
                            a{

                                max-width: 100%;
                                display: inline-block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;  
                            }
                        }
                        .author-line{
                            margin-top: 0px;
                            margin-bottom: 4px;
                            color: @my;
                            font-style: italic;
                            font-size: @fsize - 1px;

                            .author-line-nogrow{
                                max-width: 100%;
                                min-width: 50px;
                                display: inline-block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap; 
                            }
                        }
                        .abstract{
                            font-size: @font-size-small;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            max-height: @abstract-line-num*@line-height;      /* fallback */
                            -webkit-line-clamp: @abstract-line-num; /* number of lines to show */
                            -webkit-box-orient: vertical;
                        }
                        /*                    .link {
                                                margin-bottom: 2px;
                                                max-width: 100%;
                                                display: inline-block;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                white-space: nowrap; 
                                            } */

                        &.social{
                            height: @twitter-line-num*@line-height + 5px;
                            .text{
                                font-size: @font-size-base;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                max-height: @twitter-text-line-num*@line-height;      /* fallback */
                                -webkit-line-clamp: @twitter-text-line-num; /* number of lines to show */
                                -webkit-box-orient: vertical;
                            }
                            .time{
                                font-size: @font-size-small;
                                margin-top: 3px;
                                font-style: italic;
                            }


                        }
                    }
                }
            }
        }

    }

    .options-outer {
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        background-color: @my-lighter;
        &:before {
            box-shadow: 0 0 4px rgba(255,255,255,.14), 0 4px 8px rgba(255,255,255,.28);
        }



        .options {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: .005em;
            display: -webkit-box;
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            align-items: center;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: wrap; /* Safari 6.1+ */
            flex-wrap: wrap;
            padding: 0 0 4px 4px;
            -webkit-box-pack: end;
            -webkit-justify-content: flex-end;
            justify-content: flex-end;

            & > div md-select {
                margin: 0 4px;
            }

            & > div md-checkbox {
                margin: 0;
            }

            .export-btn{
                width: 30px;
                height: 30px;
                min-height: 30px;
                padding: 3px;
                padding-bottom: 0;
            }
        }
    }
}