
.complexity-outer {
  width: 100%;
  height: 100%;
  position: relative;
}

svg.complexity {
  path {
    fill: none;
  }

  path.arc {
    cursor: move;
  }

  .node {
    font-size: 12px;
  }

  .node:hover {
    fill: #1f77b4;
  }

  .link {
    stroke: #1f77b4;
    stroke-opacity: .4;
    pointer-events: none;
  }

  .link.select-source, .link.select-target, .link.select-bidirectional {
    stroke-opacity: 1;
    //stroke-width: 2px;
  }

  .node.source {
    fill: #d62728;
  }
  .node.hidden {
    opacity: 0;
  }

  .link.select-bidirectional {
    stroke: black;
  }

  .link.select-target {
    stroke: #d62728;
  }

  .node.target {
    fill: #2ca02c;
  }

  .link.select-source {
    stroke: #2ca02c;
  }

  a:link, a:visited {
    color: #777;
    text-decoration: none;
  }
  a:hover {
    color: #666;
  }

  blockquote {
    margin: 0;
  }

  blockquote:before {
    content: "â€œ";
    position: absolute;
    left: -.4em;
  }

  blockquote:after {
    content: "â€";
    position: absolute;
  }

  .invert {
    background: #1f1f1f;
    color: #dcdccc;
  }
  .invert h2, .invert h3 {
    color: #7f9f7f;
  }

  .string, .regexp {
    color: #f39;
  }

  .keyword {
    color: #00c;
  }

  .comment {
    color: #777;
    font-style: oblique;
  }

  .number {
    color: #369;
  }

  .class, .special {
    color: #1181B8;
  }

  /*svg {
    position: absolute;
    top: -80px;
    left: -160px;
  }*/
}