/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Apr 23, 2016, 2:13:53 PM
    Author     : timkos
*/

.login{
    //.fullscreen;
    background-image: url(../../img/background.jpg);
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;

    .login-inner{
        height: 100%;
    }

    md-toolbar .md-button{
        margin-left: 2px;
        margin-right: 2px;
        color: @brand-primary !important;
    }

    .error-block{
        margin-top: 5px;
        color: @brand-danger;
    }
}