
.treemap-outer {
  width: 100%;
  height: 100%;
  overflow: hidden;

}

svg.treemap{
  .cell{

    rect {
      opacity: .85;
    }

    rect:hover {
      opacity: 1;
    }
  }

  rect {
    cursor: pointer;
  }

  .parent{
    pointer-events: none;
  }
}

.d3plus_tooltip_data_container{
  font-size: 13px;
  line-height: 14px;
  .t_item{
    float: left;
    width: 100%;
    .t_child_name{
      float: left;
      padding: 3px 6px;
    }

    .t_child_score{
      float: right;
      padding: 3px 6px;
    }
  }
}

.nvtooltip{
  font-size: 12px;
  border:none;
  padding: 6px 3px 3px 3px;
  background: rgba(255,255,255, 1);
  border-radius: 0;
  color: rgb(68, 68, 68);
  font-weight: normal;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 3px;
}
