/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Apr 23, 2016, 10:12:48 PM
    Author     : timkos
*/
.main-tools-menu{
    background-color: @my-background;
    z-index: 1000;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 16px;
    //max-height: 54px;
    //overflow-y: auto;



    &>div{
        margin: 0 5px;

        md-chips{
            //font-size: @font-size-large;
            md-chips-wrap{
                padding-bottom: 0px;
                margin-bottom: 2px;
                height: 42px;
                overflow-y: auto;

                &.md-focused{
                    //box-shadow: 0 2px @brand-primary;
                }

                md-chip{
                    //color: @text-color;
                    min-width: 75px;
                    margin-top: 4px;

                    &:not(.md-focused){
                        background-color: #c0c0c0;
                        //color: @text-color;

                    }

                    .md-chip-content{
                        line-height: 14px;
                        padding-top: 3px;
                        padding-right: 0 !important;
                        .type{
                            font-size: @font-size-small;
                            font-style: italic;
                        }
                        .type_exclude{
                            font-size: @font-size-small;
                            font-style: italic;
                            color: @brand-danger;
                        }
                        .text{
                            //font-size: @font-size-base;
                            font-weight: 500;
                        }
                        .wrap{
                            float: left;
                        }
                        .source{
                            float: left;
                            margin-right: 3px;
                            color: #9E9E9E;
                        }
                    }
                    .md-chip-remove{
                        width: 20px;
                        md-icon{
                            //color: @text-color;
                            left: 50%;
                        }
                    }
                }
            }



/*            .md-chip-input-container md-autocomplete input{
                color: #000;
                &::-webkit-input-placeholder{
                    color:    @my-lighter;
                }
                &:-moz-placeholder{
                    color:    @my-lighter;
                }
                &::-moz-placeholder{
                    color:    @my-lighter;
                }
                &:-ms-input-placeholder {
                    color:    @my-lighter;
                }
            }*/
        }
    }

    .popover-info {
        position: absolute;
        bottom: 58px;
        z-index: 10;
        width: 400px;
        max-width: 90%;
        background-color: rgba(0,0,0,0.85);
        color: rgba(255, 255, 255, 0.87);
        padding: 10px;
        font-size: 14px;
        border-radius: 8px;

        .title{
            font-size: 16px;
            md-icon {
                color: rgba(255, 255, 255, 0.87);
                cursor: pointer;
            }
            margin-bottom: 8px;
        }

        md-divider{
            border-top-color: rgba(255, 255, 255, 0.3);
        }

        .content > div {
            margin-top: 10px;
        }
    }

    .popover-info:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: rgba(0, 0, 0, 0.87);
        border-width: 8px;
        margin-left: -8px;
    }
}

.main-tools-autocomplete {
        li {
            height: 48px;
            padding-top: 8px;
            padding-bottom: 8px;
            white-space: normal;

            .item-metadata, .item-title{
              display: block;
              line-height: 1.3;
              white-space: nowrap;
            }

            md-icon {
              margin-left: 0;
              margin-right: 8px;
              padding: 0;
              height: 18px;
              width: 18px;
              color: #9E9E9E;
            }
            .item-metadata{
              font-size: @font-size-small;
            }
        }
}

