.timeline-list-outer{
    height: 100%;
    display: block;
    position: relative;
    
    .timeline-list-inner{
        position: relative;
        overflow: hidden;

        @journal-col-width: 200px;
        @journal-col-width-small: 100px;
        @date-col-width: 100px;
        @date-col-width-small: 80px;

        @fsize: @font-size-base;
        @line-height: @fsize+3px;
        @abstract-line-num: 2;
        @line-num: 1+@abstract-line-num;


        font-size: @fsize;
        line-height: @line-height;

        


        .tl-counter{
            position: absolute; 
            right: 0; 
            bottom: 0;
            background-color: rgba(255,255,255,0.4); 
            font-size: @font-size-small;
            padding-right: 5px; 
        }


        .tl-journal{ 
            margin-top: 3px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            max-height: 66px;      /* fallback */
            -webkit-line-clamp: 3; /* number of lines to show */
            -webkit-box-orient: vertical;
        }

        .tl-fixed-journal{
            position: absolute; 
            left: 5px; 
            top: 0;
            width: @journal-col-width;
            overflow: hidden;

            @media (max-width : 800px){
                width: @journal-col-width-small;
            }
            .tl-journal{
                .tl-journal;
            }
        }

        .tl-date{ 
            display: inline-block;
            position: relative;
            z-index: 20;
            line-height: 16px;
            min-width: 0;
            font-size: 14px;
            font-weight: 500;
            padding-top: 12px;
            margin-top: 5px;
            margin-bottom: 5px;
            width: 56px;
            height: 56px;
            text-align: center;
            vertical-align: middle;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
            border-radius: 50%;
            overflow: hidden;
            background-color: rgb(97,97,97);
            color: white;
        }
        .tl-fixed-date{
            position: absolute; 
            left: @journal-col-width+5px;
            width: @date-col-width;
            top: 0;
            text-align: center;
            .tl-date{
                .tl-date;
            }

            @media (max-width : 800px){
                left: @journal-col-width-small+5px;
                width: @date-col-width-small;
            }
        }


        .md-virtual-repeat-container {
            position: absolute;
            top:0;
            left:0;
            height: 100%;
            width: 100%;
            -webkit-mask-image: -webkit-gradient(linear, left top, 
            left bottom, color-stop(0%,rgba(255,255,255,1)),
            color-stop(85%,rgba(255,255,255,1)),color-stop(100%,rgba(255,255,255,0)));
            
            

            &:before{
                top: 0;
                bottom: 0;
                position: absolute;
                content: " ";
                width: 3px;
                background-color: rgba(97,97,97,0.5);
                left: @journal-col-width + @date-col-width/2;
                margin-left: 4px;
                @media (max-width : 800px){
                    left: @journal-col-width-small + @date-col-width-small/2;
                }
            }

            .md-virtual-repeat-offsetter{
                margin-bottom: 50px;
                margin-top: 0px;

                .repeated-item {
                    padding-left: 5px;
                    padding-right: 10px;
                    height: @line-num*@line-height + 15px;
                    overflow-y: hidden;
                    position: relative;



                    .repeated-item-inner{

                        .journal-col{
                            width: @journal-col-width;
                            overflow: hidden;
                            height: 100%; 
                            max-height: 100%;
                            @media (max-width : 800px){
                                width: @journal-col-width-small;
                            }

                            .tl-journal{
                                .tl-journal;
                            }
                        }

                        .date-col{
                            width: @date-col-width;
                            overflow: hidden;
                            position: relative;
                            text-align: center;

                            .tl-date{
                                .tl-date;
                            }
                            @media (max-width : 800px){
                                width: @date-col-width-small;
                            }
                        }

                        .main-col{
                            padding-bottom: 10px;

                            .title{
                                height: @line-height;
                                margin-top: 5px;
                                margin-bottom: 4px;
                                font-weight: 500;
                                a{

                                    max-width: 100%;
                                    display: inline-block;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;  
                                    cursor: pointer;

                                    *{
                                        margin: 0;
                                        padding: 0;
                                    }
                                }
                            }
                            .abstract{
                                font-size: @fsize - 1px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                max-height: @abstract-line-num*@line-height;      /* fallback */
                                -webkit-line-clamp: @abstract-line-num; /* number of lines to show */
                                -webkit-box-orient: vertical;

                                *{
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
    
    .tl-iframe-outer{
        .md-toolbar-tools{
            font-size: 16px;
            line-height: 16px;
            
            .tl-iframe-title, .tl-iframe-journal{
                max-width: 100%;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap; 
            }
            .tl-iframe-journal{
                font-size: 14px;
            }
        }
        .tl-iframe{
            border: 5px solid rgb(216,67,21);
            border-top: none;
        }
    }
}