
.geomap-outer{
  width: 100%;
  height: 100%;

  svg{
    display: block;
    margin: auto;
  }

  .land {
    fill: #222;
  }

  .boundary {
    fill: none;
    stroke: #fff;
    stroke-width: 1px;
  }

  .tooltip-container {
    position: absolute;
    background-color: #fff;
    color: #000;
    padding: 10px;
    border: 1px solid;
    display: none;
  }

  .tooltip_key {
    font-weight: bold;
  }

  .tooltip_value {
    margin-left: 20px;
    float: right;
  }
}