


.admin-view{
  display: block;
  position: absolute;
  left:0;
  right: 0;
  bottom: 0;
  top: 95px;
  overflow: auto;

  .search-row{
    padding-right: 18px;
    padding-bottom: 5px;
    margin: 0;

    .input-search-container{
      margin: 18px 0 0 15px;
    }
  }

  .datepicker-container{
    margin: 18px 0 0;
  }
  .buttons-container{
    margin: 14px 0 4px;
  }

  .md-errors-spacer{
    min-height: 0px;
  }
  .mdtTableContainer {
    tr{
      background-color: #f9f9f9;
      th{
        background-color: #f9f9f9;
        height: 40px;
        line-height: 40px;
        color: @my-base;
        font-weight: bold;
        font-size: 13px;
      }
      .noResultMessage{
        padding-left: 20px;
      }
      td{
        height: 40px;
        padding: 3px 0;

        .table-svg-container{
          width: 350px;
          height: 65px;
          @media (max-width : 1800px){
            width: 250px;
            height: 60px;
          }
          @media (max-width : 1200px){
            width: 170px;
            height: 55px;
          }
          @media (max-width : 900px){
            width: 125px;
            height: 50px;
          }
          @media (max-width : 700px){
            width: 100px;
            height: 45px;
          }
        }
      }
    }
    tr:nth-of-type(odd){
      background-color: #fff;
    }
    .column{
        padding-left: 15px;
    }
  }
}

.scopes-view{
  display: block;
  position: absolute;
  left:0;
  right: 0;
  bottom: 0;
  top: 40px;
  overflow: auto;

  .search-row{
    padding-right: 18px;
    padding-bottom: 5px;
    margin: 0;

    .input-search-container{
      margin: 18px 0 0 15px;
    }
  }

  .datepicker-container{
    margin: 18px 0 0;
  }
  .buttons-container{
    margin: 14px 0 4px;
  }

  .md-errors-spacer{
    min-height: 0px;
  }
  .mdtTableContainer {
    tr{
      background-color: #f9f9f9;
      th{
        background-color: #f9f9f9;
        height: 40px;
        line-height: 40px;
        color: @my-base;
        font-weight: bold;
        font-size: 13px;
      }
      .noResultMessage{
        padding-left: 20px;
      }
      td{
        height: 40px;
        padding: 3px 0;

        .table-svg-container{
          width: 350px;
          height: 65px;
          @media (max-width : 1800px){
            width: 250px;
            height: 60px;
          }
          @media (max-width : 1200px){
            width: 170px;
            height: 55px;
          }
          @media (max-width : 900px){
            width: 125px;
            height: 50px;
          }
          @media (max-width : 700px){
            width: 100px;
            height: 45px;
          }
        }
      }
    }
    tr:nth-of-type(odd){
      background-color: #fff;
    }
    .column{
      padding-left: 15px;
    }
  }
}

.md-dialog-content{
  .input-max-width{
    max-width: 300px;
  }
}
