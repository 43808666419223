@import "var_custom";

@back-navigation-height: 40px;
@document-details-back-navigation-max-width: 400px;

.document-details-item {

  .details-title-outer{
    position: relative;
    padding-right: 30px;
  }
  .export-btn{
    position: absolute;
    right: -10px;
    top: -8px;
    margin: 0;
  }

  .divider {
    border-top-width: 1px;
    border-top-style: solid;
    margin: 0;
    border-color: rgba(0, 0, 0, 0.12);
    margin-top: 5px;
    margin-bottom: 5px;
  }

  @line-height: @font-size-base+3px;

  .details-title {
    margin-top: 0px;
    margin-bottom: 4px;
    font-weight: 500;
    max-width: 100%;
    color: @link-hover-color;

    .epo-link {
      font-size: @font-size-small;
      font-style: italic;
    }
  }

  .details-title-no-url {
    margin-top: 0px;
    margin-bottom: 4px;
    font-weight: 500;
    max-width: 100%;
 }

  .details {
    margin-top: 10px;
  }

  .abstract {
    font-size: @font-size-small;
    overflow: hidden;
    line-height: @line-height;
  }

  .item {
    font-size: @font-size-small;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: @line-height;
  }

  .row {
    .title {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .title span {
      font-weight: 400;
    }

    .content {
      padding-bottom: 5px;
    }
  }

  .keywords-link {
    font-size: @font-size-small;
    line-height: @line-height;
    color: @my-dark;
    text-decoration: none;
  }

  .keywords-link:link {
    color: @my-darker;
    text-decoration: none;
  }

  .keywords-link:visited {
    color: @my-dark;
    text-decoration: none;
  }

  .keywords-link:hover {
    color: @my-darker;
    text-decoration: underline;
  }

  .image-preview {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .sub-header {
    padding-bottom: 10px;

    .bold {
      font-weight: bold;
    }
  }
}

.document-details-back-container {
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  border-top: solid 1px @my-lighter;
  height: @back-navigation-height;

  .component {
    margin: 0 auto;

    .navigation {
      display: inline-block;

      .back-button {
        margin-right: 0;
      }

      .back-button-more {
        margin-left: 0;
      }

      .forward-button {
        margin-left: 0;
      }

      .forward-button-more {
        margin-right: 0;
      }
    }
  }
}

.document-details-back-popup-item {
  cursor: pointer;
  max-width: @document-details-back-navigation-max-width;
  overflow: hidden;

  span {
    max-width: @document-details-back-navigation-max-width;
    text-overflow: ellipsis;
    font-size: @font-size-small;
  }
}

.document-details-back-popup-item:hover {
  background-color: @my-lighter;
}

.document-details-back-body {
  margin-bottom: @back-navigation-height;
  overflow-x: auto;
}
