.document-geo{
  width: 100% !important;
  height: 100% !important;
}

.document-geo-map {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}

.document-geo-map-refresh {
  color: @brand-primary;
}

.document-geo-map-export {
  color: @brand-primary;
}

.document-geo-settings {
  &-item {
    margin: 0;
    background-color: white;
    min-width: 120px;
  }

  &-container {
    float: right;
  }
}

// used to override leaflet map z-index values to work with other popups properly
.leaflet {
  &-map-pane canvas {
    z-index: 50; // original 100
  }

  &-map-pane svg {
    z-index: 51; // original 200
  }

  &-tile-pane {
    z-index: 51; // original 200
  }

  &-pane {
    z-index: 52; // original 400
  }

  &-overlay-pane {
    z-index: 52; // original 400
  }

  &-shadow-pane {
    z-index: 53; // original 500
  }

  &-marker-pane {
    z-index: 54; // original 600
  }

  &-tooltip-pane {
    z-index: 55; // original 650
  }

  &-popup-pane {
    z-index: 56; // original 700
  }

  &-zoom-box {
    z-index: 57; // original 800
  }

  &-control {
    z-index: 57; // original 800

    a {
      text-decoration: none !important;
    }
  }

  &-top,
  &-bottom {
    z-index: 58; // original 1000
  }
}