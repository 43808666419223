/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Oct 29, 2016, 12:04:01 PM
    Author     : timkos
*/

.word-details-outer{
    height: 100%;

    .word-history{
        height: 100%;

        .option{
            padding-top: 3px;
            padding-bottom: 3px;
        }

        md-radio-button{
            @radioSize: 15px;
            .md-label{
                margin-left: 20px;
            }
            .md-container {
                height: @radioSize;
                width: @radioSize;
            }
            .md-off, .md-on {
                width: @radioSize;
                height: @radioSize;
            }
        }

        md-checkbox {
            @chackboxSize: 16px;
            .md-label{
                margin-left: 24px;
            }

            .md-container {
                height: @chackboxSize;
                width: @chackboxSize;

                .md-icon{
                    height: @chackboxSize;
                    width: @chackboxSize;
                }
            }

            &.md-checked .md-icon:after{
                left: 2.66667px;
                top: -1.77778px;
                width: 6.16667px;
                height: 12.33333px;
            }
        }
    }

    .svg-container{
        svg {
            width: 100%;
            height: 100%;

            .axis path,
            .axis line {
                fill: none;
                stroke: #000;
                shape-rendering: crispEdges;
            }

            font-size: 14px;

            .nv-legendWrap .nv-series text {
                //font-size: 14px;
            }
        }
    }


}
