.word-cloud-outer{
    //padding: 5px;
    height: 100%;
    
    .svg-container{
        min-height: 140px;
        svg text.word{
            cursor: pointer;
            //fill: @text-color;

            &:hover,&.special-word{
                fill: @brand-primary
            }
        }
    }
    .slider-container{
        padding-left: 10px;
        .slider{
            margin-left: 15px;
            margin-right: 10px;
            position: relative;
            .slider-range{
                position: absolute;
                font-size: 11px;
                font-style: italic;
                color: @my;
                &.slider-begin{
                    left:0;
                }
                &.slider-end{
                    right:0;
                }
            }

            md-slider{
            }
        }

        &.slider-top{
            margin-top: 8px;
            .slider{
                .slider-range{
                    top:0;
                }
            }
        }
        &.slider-bottom{
            .slider{
                .slider-range{
                    bottom:0;
                }
            }
        }
    }

}

