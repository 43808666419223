/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Apr 23, 2016, 2:13:53 PM
    Author     : timkos
*/

.md-sidenav-right{
    md-select{
        width: 100%;
        margin: 0px;
    }
}