/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Apr 23, 2016, 1:23:03 PM
    Author     : timkos
*/
*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/*
  .layout-fill {
    margin: 0 !important;
    width: 100% !important;
    min-height: 100% !important;
    height: auto;
    }*/

body {
  min-height: 100%;
  //height: 100%;
  //min-width: 750px; //temporary
  color: #222222;
  background-color: @my-background;
}

button, html, input, select, textarea, body, svg.main-svg {
  font-family: Roboto, Helvetica Neue, sans-serif;
}

.toast-top-right {
  top: 57px;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-c-a {
  flex: 1 1 auto;
}

.flex-c-g {
  flex: 1 0 auto;
}

.flex-c-n {
  flex: none;
}

.flex-c-40 {
  .flex-c-n;
  width: 40%;
  margin-left: 5px;
}

.flex-c-50 {
  .flex-c-n;
  width: 50%;
  margin-left: 5px;
}

.flex-c-25 {
  flex: 1 0 auto;
}

.flex-c-20 {
  .flex-c-n;
  width: 20%;
  margin-left: 5px;
}

.flex-c-33 {
  .flex-c-n;
  width: 33.3%;
}

.fh {
  height: 100%;
}

.p5 {
  padding: 5px;
}

.widget {

  .flex-c-a;
  .flex-col;
  .widget-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .widget-header{
      padding-left: 15px;
      background-color: @my-lighter;
      height: 30px;
      color: @brand-primary;

      md-icon{
        //color: @brand-primary-lighten;
      }
    }

    .widget-header-simple{
      padding-left: 15px;
      background-color: @my-lighter;
      height: 30px;
      //color: @brand-primary;

      md-icon{
        //color: @brand-primary-lighten;
      }
    }

  }
  position: relative;
  background-color: #ffffff;
  min-height: 300px;
  min-width: 350px;
  overflow: hidden;

  &.widget-desktop {
    margin: 5px;
  }
}

.my-layout {
  .flex-c-a;
  .flex-row;
  overflow-x: auto;

  .my-row {
    min-width: 360px;
    .flex-c-g;
    overflow-y: auto;
    position: relative;

    .my-row-inner {
      .fullscreen;
      .flex-col;
      overflow-y: auto;

      .my-col {
        .flex-c-g;
        .flex-col;
      }
    }
  }
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.master {
  .fullscreen;
  min-height: 200px;
  background-color: @my-background;
}

.fullscreen-dir {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.transparent-black {
  background-color: @transparent-black3;
}

.transparent-white {
  background-color: @transparent-white6;
}

.margin-0 {
  margin: 0;
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}

.margin-b10 {
  margin-bottom: 10px;
}

.padding-t10b0 {
  padding-top: 10px !important;
  padding-bottom: 0 !important;
}

.margin-tb0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.vertical-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-lg {
  .fullscreen;
  min-height: 50px;
  & > span {
    .vertical-center;
  }
  font-size: @font-size-large;
  font-weight: bold;
  line-height: 26px;
  .fa {
    margin-right: 3px;
  }
  img {
    height: 40px;
  }
}

.loading-sm {
  .fullscreen;
  min-height: 50px;
  & > span {
    .vertical-center;
  }
  font-size: @font-size-base;
  font-weight: bold;
  line-height: 22px;
  img {
    height: 35px;
  }
}

.loading-inline {
  font-size: @font-size-base;
  font-weight: bold;
  line-height: 18px;
  img {
    height: 35px;
  }
}

.red {
  background-color: red;
}

.blue {
  background-color: blue;
}

.green {
  background-color: green;
}

.red-text {
  color: red;
}

.blue-text {
  color: blue;
}

.green-text {
  color: green;
}

a:not(.md-button) {
  color: @brand-primary;
  text-decoration: none;
}

a:hover:not(.md-button) {
  color: @link-hover-color;
}

a:visited:not(.md-button) {
  color: @link-hover-color;
}

a:hover:not(.md-button) {
  text-decoration: underline;
}

//fixed crome problem with autofill in password input!
.md-input[type=password]:-webkit-autofill ~ label:not(.md-no-float) {
  transform: translate3d(0, 6px, 0) scale(0.75);
  transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s;
  width: ~"calc((100% - 18px) / 0.75)";
  color: rgba(0, 0, 0, 0.54);
}

md-list md-list-item {
  md-icon:first-child:not(.md-avatar-icon) {
    margin: auto 20px auto 0 !important;
  }
}

md-list md-list-item.sublist .md-list-item-inner {
  padding-left: 20px;
}

.md-button.md-icon-button.lg-icon-button {
  width: 48px;
  height: 48px;
  md-icon {
    font-size: 32px;
  }
}

md-toast {
  &.md-top {
    top: 45px;
  }
  &.info {
    .md-toast-content {
      background-color: @brand-info;
    }
  }

  &.success {
    .md-toast-content {
      background-color: @brand-success;
    }
  }

  &.error {
    .md-toast-content {
      background-color: @brand-danger;
    }
  }

  &.warning {
    .md-toast-content {
      background-color: @brand-warning;
    }
  }

  @media (max-width: 959px){
    //top: 40px;
    bottom: 54px;
  }

}

/* FIX for tabs !!!*/
md-dialog {
  -webkit-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
}

.md-button.auto-size, .auto-size {
  width: auto;
  min-width: auto;
  height: auto;
  min-height: auto;
  margin: 0;
  padding: 0;
}

.fa, .mdi-set {
  font-size: 24px;
}

.headroom {
  will-change: transform;
  transition: transform 300ms linear;
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--unpinned {
  transform: translateY(-100%);
}

md-menu-item {
  .no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  md-menu {

    .md-button {
      text-align: left;
      display: inline-block;
      border-radius: 0;
      margin: auto 0;
      font-size: 15px;
      text-transform: none;
      font-weight: 400;
      min-height: 48px;
      height: 48px;
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;

      md-icon {
        margin: auto 16px auto 0;
      }
    }
  }
}

.hide-validation-error, .md-chip-input-container{
  .md-errors-spacer{
    display: none;
  }
}

md-select.icon-select{
  max-width: 100px;
}

.md-select-menu-container.icon-select{
  max-width: 400px;
  min-width: 100px;
  md-select-menu{
    width: 54px;
    md-option{
      padding: 0;
      height: 36px;
      .md-text{
        padding: 0;
      }

      &>.md-ripple-container{
        display: none;
      }
    }
  }
}

.action-icon{
  cursor: pointer;
}

