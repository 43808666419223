.social-details{
    height: 100%;
    overflow-x: hidden;
    position: relative;
    padding: 5px;

    .export-btn{
        position: absolute;
        top: 0;
        right: -5px;
    }

    .social-details-details{
        overflow: auto;
        margin-right: -5px;
        margin-left: -5px;

        md-content{
            min-width: 250px;
            background-color: transparent;
            overflow: visible;

            md-list{
                padding-top: 0;

                .md-subheader{
                    background-color: @brand-primary-lighten;
                    height: 44px;
                }

                md-list-item{
                    height: auto;
                    min-height: 22px;
                    height: 22px;
                    overflow: hidden;
                    align-items: flex-start;
                    line-height: 12px;
                    font-size: 12px;
                }
            }
        }
    }

}