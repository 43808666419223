.social-agg{
    height: 100%;
    overflow-x: hidden;
    position: relative;
    overflow-y: auto;

    .social-agg-inner{

        md-content{
            min-width: 250px;
            background-color: transparent;
            overflow-y: hidden;

            md-list{
                padding-top: 0;

                .md-subheader{
                    background-color: @brand-primary-lighten;
                    height: 44px;
                }

                md-list-item{

                    &.md-3-line{
                        min-height: 55px;
                        height: 55px;
                    }

                    &.md-2-line{
                        min-height: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }

}