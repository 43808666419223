/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Apr 23, 2016, 10:12:48 PM
    Author     : timkos
*/
.header{
    
    md-toolbar{
        @height: 40px;
        height: @height;
        min-height: @height;
        
        .md-toolbar-tools{
          height: @height;
          .pages{

            .selected{
              md-icon{
                color: @brand-primary;
              }
            }

          }


          .popover-info {
            position: absolute;
            top: 48px;
            z-index: 10;
            width: 300px;
            max-width: 90%;
            background-color: rgba(0,0,0,0.85);
            color: rgba(255, 255, 255, 0.87);
            padding: 10px;
            font-size: 14px;
            border-radius: 8px;

            .title{
              font-size: 16px;
              md-icon {
                color: rgba(255, 255, 255, 0.87);
                cursor: pointer;
              }
              margin-bottom: 8px;
            }

            md-divider{
              border-top-color: rgba(255, 255, 255, 0.3);
            }

            .content > div {
              margin-top: 10px;
            }
          }

          .popover-info:after {
            bottom: 100%;
            left: 26px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-bottom-color: rgba(0,0,0,0.85);
            border-width: 8px;
            margin-left: -8px;
          }

        }
    }
}

.layout-menu-content, .channel-menu-content, .source-menu-content{
  md-menu-item{
    position: relative;
    .md-button {
      //position: relative;
      width: 100%;
      padding-right: 34px;
      .default{
        font-weight: bold;
      }
    }

    .edit-icon{
      position: absolute;
      right: 10px;
      top: 12px;
      width: 24px;
      padding: 0;
      cursor: pointer;

    }
  }
}

